$enable-negative-margins: true;

$black: #000 !default;
$warning-strong: #ffb101 !default;

$body-bg: rgb(250, 250, 250);
$body-color: rgba(33, 33, 33);

$box-shadow-lg: 0 0rem 3rem rgba($black, 0.35);
$box-shadow-menu: 6px 6px 8px -4px rgba($black, 0.5);

$modal-backdrop-opacity: 0.75;

$tooltip-margin: calc(0.375rem + 1px);

$position-values: (
    0: 0,
    35: 35%,
    50: 50%,
    100: 100%,
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 728px,
    lg: 992px,
    xl: 1200px,
    xxl: 1520px,
);

$container-max-widths: (
    xs: 320px,
    sm: 576px,
    md: 728px,
    lg: 992px,
    xl: 1200px,
    xxl: 1520px,
);

@import 'bootswatch/dist/spacelab/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

$form-floating-padding-y: $input-padding-y;
$form-floating-height: add(2.25rem, $input-height-border);
$navbar-brand-color: $navbar-light-brand-color;

@import 'bootstrap/scss/bootstrap';
@import 'bootswatch/dist/spacelab/bootswatch';
@import 'bootstrap-icons/font/bootstrap-icons';

// Compatibilización con Bootstrap 5 de ng-select (multiselect de formularios)
.ng-select {
    min-height: 38px;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    &.ng-select-filtered.ng-touched.ng-invalid {
        background-position: right calc(4.5em + 0.1875rem) center;
    }
    &.ng-touched.ng-invalid {
        background-position: right calc(2.25em + 0.1875rem) center;
        padding-right: 0;
    }
    &.ng-select-opened {
        > .ng-select-container {
            .ng-arrow-wrapper {
                border-bottom-right-radius: 0px;
                .ng-arrow {
                    top: -2px;
                    border-color: transparent transparent var(--bs-btn-color);
                    border-width: 0 5px 5px;
                }
                &:hover {
                    .ng-arrow {
                        border-color: transparent transparent var(--bs-btn-hover-color);
                    }
                }
            }
        }
        &.ng-select-top {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
        &.ng-select-right {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &.ng-select-bottom {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        &.ng-select-left {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    &.ng-select-focused {
        color: var(--bs-body-color);
        background-color: var(--bs-body-bg);
        border-color: #a2b7cd;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(68, 110, 155, 0.25);
    }
    &.ng-select-disabled {
        background-color: var(--bs-secondary-bg);
        opacity: 1;
        .ng-arrow-wrapper {
            display: none;
        }
    }
    &.ng-select-multiple {
        padding-left: 0.375rem;
        .ng-value-container {
            padding-top: 0.25rem;
            padding-right: 0;
            padding-bottom: 0;
            .ng-value {
                @extend .btn-group;
                margin-bottom: 0.25rem;
                margin-right: 0.375rem;
                .ng-value-icon {
                    @extend .btn;
                    @extend .btn-outline-primary;
                    padding: 3px 5px;
                    line-height: 1;
                    font-size: 20px;
                }
                .ng-value-label {
                    @extend .btn;
                    @extend .btn-outline-primary;
                    @extend .active;
                    padding: 3px 5px;
                    line-height: 1;
                    font-size: 17px;
                }
            }
            .ng-input {
                padding: 0 0 3px 3px;
            }
            .ng-placeholder {
                padding-left: 3px;
            }
        }
        &.ng-select-disabled {
            .ng-value-label {
                border-top-left-radius: var(--bs-btn-border-radius) !important;
                border-bottom-left-radius: var(--bs-btn-border-radius) !important;
            }
        }
    }
    .ng-has-value .ng-placeholder {
        display: none;
    }
    .ng-value-container {
        overflow: auto;
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
        .ng-placeholder {
            color: $input-placeholder-color;
        }
    }
    .ng-spinner-zone {
        padding: 5px 5px 0 0;
    }
    .ng-arrow-wrapper {
        @extend .btn;
        @extend .btn-outline-secondary;
        border-width: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: var(--bs-border-width) solid var(--bs-border-color);
        padding: 0.375rem 0.75rem;
        &:hover {
            .ng-arrow {
                border-top-color: var(--bs-btn-hover-color);
            }
        }
        .ng-arrow {
            border-color: var(--bs-btn-color) transparent transparent;
            border-style: solid;
            border-width: 5px 5px 2.5px;
        }
    }
    .ng-clear-wrapper {
        @extend .btn;
        @extend .btn-outline-secondary;
        border-width: 0px;
        border-radius: 0px;
        border-left: var(--bs-border-width) solid var(--bs-border-color);
        padding: 0.375rem 1.375rem 0.375rem 0.75rem;
    }
}

.ng-dropdown-panel {
    @extend .dropdown-menu;
    display: block;
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    left: 0;
    z-index: 1060 !important;
    &.ng-select-top {
        bottom: 100%;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin-bottom: -1px;
    }
    &.ng-select-right {
        left: 100%;
        top: 0;
        border-top-left-radius: 0;
        margin-bottom: -1px;
    }
    &.ng-select-bottom {
        top: 100%;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        margin-top: -1px;
    }
    &.ng-select-left {
        left: -100%;
        top: 0;
        border-top-right-radius: 0;
        margin-bottom: -1px;
    }
    .ng-dropdown-header {
        border-bottom: var(--bs-border-width) solid var(--bs-border-color);
        padding: 5px 7px;
    }
    .ng-dropdown-footer {
        border-top: var(--bs-border-width) solid var(--bs-border-color);
        padding: 5px 7px;
    }
    .ng-dropdown-panel-items {
        .ng-optgroup {
            user-select: none;
            padding: 8px 10px;
            font-weight: 500;
            cursor: pointer;
            &.ng-option-disabled {
                @extend .disabled;
            }
            &.ng-option-selected,
            &.ng-option-selected.ng-option-marked {
                font-weight: 600;
            }
        }
        .ng-option {
            @extend .dropdown-item;
            &.ng-option-selected,
            &.ng-option-selected.ng-option-marked {
                @extend .active;
            }
            &.ng-option-marked {
                background-color: var(--bs-dropdown-link-hover-bg);
                color: var(--bs-dropdown-link-hover-color);
                border: var(--bs-border-width) solid var(--bs-border-color);
            }
            &.ng-option-disabled {
                @extend .disabled;
            }
            &.ng-option-child {
                padding-left: 22px;
            }
            .ng-tag-label {
                font-size: 80%;
                font-weight: 400;
                padding-right: 5px;
            }
        }
    }
}

.was-validated .ng-select {
    &.ng-valid {
        @extend .is-valid;
        .ng-arrow-wrapper {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

// Íconos personalizados
.bi-custom::before {
    display: inline-block;
    content: '';
    vertical-align: -0.125em;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    height: 1rem;
    width: 1rem;
}

.bi-unlink::before {
    @extend .bi-custom;
    // background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23fff'><path d='M304.083 405.907c4.686 4.686 4.686 12.284 0 16.971l-44.674 44.674c-59.263 59.262-155.693 59.266-214.961 0-59.264-59.265-59.264-155.696 0-214.96l44.675-44.675c4.686-4.686 12.284-4.686 16.971 0l39.598 39.598c4.686 4.686 4.686 12.284 0 16.971l-44.675 44.674c-28.072 28.073-28.072 73.75 0 101.823 28.072 28.072 73.75 28.073 101.824 0l44.674-44.674c4.686-4.686 12.284-4.686 16.971 0l39.597 39.598zm-56.568-260.216c4.686 4.686 12.284 4.686 16.971 0l44.674-44.674c28.072-28.075 73.75-28.073 101.824 0 28.072 28.073 28.072 73.75 0 101.823l-44.675 44.674c-4.686 4.686-4.686 12.284 0 16.971l39.598 39.598c4.686 4.686 12.284 4.686 16.971 0l44.675-44.675c59.265-59.265 59.265-155.695 0-214.96-59.266-59.264-155.695-59.264-214.961 0l-44.674 44.674c-4.686 4.686-4.686 12.284 0 16.971l39.597 39.598zm234.828 359.28l22.627-22.627c9.373-9.373 9.373-24.569 0-33.941L63.598 7.029c-9.373-9.373-24.569-9.373-33.941 0L7.029 29.657c-9.373 9.373-9.373 24.569 0 33.941l441.373 441.373c9.373 9.372 24.569 9.372 33.941 0z'/></svg>");
    background-image: url('/assets/icon-unlink.svg');
    filter: invert(1);
}

// Espaciado entre íconos y textos en desplegables, botones, etc.
td i + span,
.navbar-nav i + span,
.dropdown-menu i + span,
.toast-header i + strong,
.dropdown-menu span.spinner-border + span {
    margin-left: 0.5rem !important;
}

span span[class^='spinner-'] {
    margin-left: 0.375rem;
}

i.instrucciones-pantalla {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
    color: rgba($info, 1);
    cursor: help;
}

[data-bs-theme='dark'] i.instrucciones-pantalla {
    color: lighten(rgba($info, 1), 15);
}

// Estilos para el menú de navegación
.navbar-nav {
    --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.85);
    strong {
        color: rgba(var(--bs-emphasis-color-rgb), 0.85);
    }
}

.navbar-brand {
    color: darken($navbar-brand-color, 25%);
}
[data-bs-theme='dark'] .navbar-brand {
    color: lighten($navbar-brand-color, 25%);
}

// Estilos para menúes desplegables y separación entre elementos
.dropdown-menu,
.ng-dropdown-panel {
    box-shadow: $box-shadow-menu;
    button + button,
    .ng-option + .ng-option {
        margin-top: 0.125rem;
    }
}

// Estilos para los popovers (Asistencia en Pantalla)
.popover {
    --bs-popover-header-color: inherit;
}

.popover-warning {
    --bs-popover-border-color: var(--bs-warning);
    --bs-popover-header-bg: var(--bs-warning);
    --bs-popover-header-color: var(--bs-white);
    --bs-popover-bg: var(--bs-warning-bg-subtle);
}

// Estilos para los toasts
.toast {
    background: none;
    .toast-header,
    .toast-body {
        border-width: var(--bs-toast-border-width);
        border-style: solid;
    }
    .toast-header {
        --bs-toast-header-color: var(--bs-white);
        border-bottom: none;
        border-color: var(--bs-toast-header-border-color);
    }
    .toast-body {
        background-color: var(--bs-toast-bg);
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        border-color: var(--bs-toast-border-color);
        border-top: none;
    }
    &.success {
        .toast-header {
            --bs-toast-header-bg: var(--bs-success);
            --bs-toast-header-border-color: var(--bs-success);
        }
        .toast-body {
            --bs-toast-border-color: var(--bs-success);
            --bs-toast-bg: var(--bs-success-bg-subtle);
        }
    }
    &.warning {
        .toast-header {
            --bs-toast-header-bg: var(--bs-warning);
            --bs-toast-header-border-color: var(--bs-warning);
        }
        .toast-body {
            --bg-toast-border-color: var(--bs-warning);
            --bs-toast-bg: var(--bs-warning-bg-subtle);
        }
    }
    &.danger {
        .toast-header {
            --bs-toast-header-bg: var(--bs-danger);
            --bs-toast-header-border-color: var(--bs-danger);
        }
        .toast-body {
            --bs-toast-border-color: var(--bs-danger);
            --bs-toast-bg: var(--bs-danger-bg-subtle);
        }
    }
    &.info {
        .toast-header {
            --bs-toast-header-bg: var(--bs-info);
            --bs-toast-header-border-color: var(--bs-info);
        }
        .toast-body {
            --bs-toast-border-color: var(--bs-info);
            --bs-toast-bg: var(--bs-info-bg-subtle);
        }
    }
}

// Extensión de la validación de formularios de Angular (Reactive Forms) para Bootstrap 5
.ng-touched.ng-invalid {
    @extend .is-invalid;
}

input:not([readonly]).ng-invalid.ng-touched ~ button,
.input-group-text:has(+ input:not([readonly]).ng-invalid.ng-touched) {
    border-color: var(--bs-form-invalid-border-color);
}

.was-validated input:not([readonly]).ng-valid.ng-touched ~ button,
.was-validated .input-group-text:has(+ input:not([readonly]).ng-valid.ng-touched) {
    border-color: var(--bs-form-valid-border-color);
}

.invalid-standalone ~ .invalid-feedback {
    padding-left: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23cd0200'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23cd0200' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: left calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.table-container.is-invalid {
    border-color: var(--bs-form-invalid-border-color);
    border-style: solid;
    border-width: var(--bs-border-width);
}

label.required-input::after {
    color: $warning;
    content: ' *';
}

// Estilos para inputs
input[readonly]:not(:disabled) {
    background-color: var(--bs-secondary-bg-subtle);
    border-width: 0;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
    top: -10px;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
    padding-top: 0.625rem;
}

.form-floating > label {
    z-index: 6;
    transition:
        opacity 0.1s ease-in-out,
        transform 0.1s ease-out;
}

// Estilos para input-groups
[ngbDatepicker] ~ button {
    border-color: var(--bs-border-color);
}

.ngb-dp-weekdays,
.ngb-dp-header {
    background-color: var(--bs-secondary-bg-subtle) !important;
}

[ngbDatepickerDayView] {
    border-width: 0 !important;
}

[ngbDatepickerDayView]:hover:not(.bg-primary),
[ngbDatepickerDayView].active:not(.bg-primary),
[ngbDatepickerDayView].btn-light:not(.disabled):hover {
    --bs-text-opacity: 1;
    background-color: rgb(var(--bs-info-rgb)) !important;
    background-image: none !important;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

// Estilos para modales
.modal-content {
    box-shadow: $box-shadow-menu;
}

.btn-fs {
    @extend .btn-close;
    background: none;
}

.btn-fs.expand {
    --bs-btn-fs-expand-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z'/></svg>");
    background: transparent var(--bs-btn-fs-expand-bg) center/1em auto no-repeat;
}

.btn-fs.contract {
    --bs-btn-fs-contract-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path d='M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707zM15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707z'/></svg>");
    background: transparent var(--bs-btn-fs-contract-bg) center/1em auto no-repeat;
}

// Estilos para tooltips
ngb-tooltip-window {
    &.bs-tooltip-top {
        margin-bottom: $tooltip-margin !important;
    }
    &.bs-tooltip-right {
        margin-left: $tooltip-margin !important;
    }
    &.bs-tooltip-bottom {
        margin-top: $tooltip-margin !important;
    }
    &.bs-tooltip-left {
        margin-right: $tooltip-margin !important;
    }
}

// Estilos generales
.seccion-advertencia {
    background: repeating-linear-gradient(-45deg, #000000, #000000 20px, #ffb101 20px, #ffb101 40px);
    span {
        border-top-right-radius: 0.375rem;
        border-top-left-radius: 0.375rem;
    }
}
